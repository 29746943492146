




































































































































































.ruleForm {
  .fromTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
